import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
    Route,
    UrlSegment,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    private isAuthenticated = false;

    constructor(private authService: AuthService, private router: Router) {
        this.authService.isAuthenticated.subscribe(
            (isAuthenticated) => (this.isAuthenticated = isAuthenticated)
        );
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkAuthentication();
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkAuthentication();
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): boolean | Observable<boolean> | Promise<boolean> {
        return this.checkAuthentication();
    }

    checkAuthentication(): boolean {
        if (this.isAuthenticated) {
            return true;
        }

        this.router.navigateByUrl('/');
        return false;
    }
}
